import React from 'react';
import DownloadButton1 from "assets/img/Download-Button-1-1.png";
import { Link } from 'react-router-dom';

function GooglePlayDownloadButton() {
  return (
    <Link to="https://play.google.com/store/apps/details?id=com.techgv.app&hl=en&gl=US">
      <img
        className="fade-right"
        width={100}
        src={DownloadButton1}
        alt="Download Button"
      />
    </Link>
  );
}

export default GooglePlayDownloadButton;