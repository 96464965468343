import React from "react";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <div>
      <section className="PrivacyPolicy-section">
        <div className="container">
          <div className="row ">
            <div className="col-12">
              <Helmet>
                <title>TecHGV Privacy Policy</title>
              </Helmet>
              <h1>TecHGV Privacy Policy</h1>
              <p>
                <strong>TecHGV</strong> is dedicated to protecting your privacy.
                This Privacy Policy outlines how we collect, use, disclose, and
                safeguard your personal information when you use our app. By
                using our services, you consent to the collection and use of
                information in accordance with this policy.
              </p>
              <p>
                <strong>Information Google Collects</strong>
              </p>
              <p>
                When you use the TecHGV app, Google may collect the following
                types of information:
              </p>
              <ol>
                <li>
                  <p>
                    <strong>Personal Information</strong>
                  </p>
                  <p>
                    <strong>Contact Details:</strong> Name, email address, phone
                    number, and company information provided during registration
                    or support requests.
                  </p>
                  <p>
                    <strong>Account Credentials:</strong> Username and password
                    for your account.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Usage Data</strong>
                  </p>
                  <p>
                    <strong>Device Information: </strong>Details about your
                    device, including model, operating system, and unique device
                    identifiers.
                  </p>
                  <p>
                    <strong>Log Data: </strong>IP address, browser type, pages
                    visited, time and date of visits, and duration of use.
                  </p>
                </li>
                <li>
                  <strong>Location Data</strong>
                </li>
                <p>
                  With your consent, we may collect real-time location
                  information to enhance the functionality of our services, such
                  as tracking vehicle movements.
                </p>
                <li>
                  <strong>Camera and Microphone:</strong>
                </li>
                <p>
                  The app may access the user's camera and microphone to allow
                  for accident report images, voice chats in the live chat
                  feature, and driver selfie verification.
                </p>

                <li>
                  <strong>Media Files</strong>
                </li>
                <p>
                  The app may access the user's media files, such as photos and
                  videos, to allow users to upload accident report images and
                  driver selfie videos.
                </p>
              </ol>

              <p>
                <strong>Why Google Collects Data</strong>
              </p>
              <p>
                Google collects information for several purposes, including:
              </p>
              <p>
                <strong>Service Improvement: </strong> To analyze and enhance
                the app's features and performance.
              </p>
              <p>
                <strong>Personalization: </strong> To provide tailored content
                and advertisements based on your preferences.
              </p>
              <p>
                <strong>Analytics: </strong> To understand user behavior and
                improve overall user experience.
              </p>
              <p>
                {" "}
                <strong>Your Privacy Controls</strong> You have several options
                to manage your privacy: used.
              </p>

              <p>
                <strong>Account Settings: </strong> : Access your Google account
                settings to manage what data is collected and how it's used.
              </p>

              <p>
                <strong>Ad Personalization: </strong> Customize your ad settings
                to control the kinds of ads you see.
              </p>
              <p>
                <strong>Data Permissions: </strong> Manage app permissions on
                your device to limit data access.
              </p>
              <h4>
                <strong>Sharing Your Information </strong>
              </h4>
              <p>
                <strong>We Keep Your Data Private</strong>
              </p>
              <p>
                We value your privacy and do not sell, trade, or share your
                personal data with any third parties for marketing purposes.
                Your information is kept confidential and is used solely for the
                functions outlined in this policy.
              </p>
              <p>
                <strong>Other Sharing Scenarios</strong>
              </p>
              <p>
                We may share your information under the following circumstances:
              </p>
              <p>
                <strong>Service Providers: </strong> We may partner with
                third-party service providers for app functionality, analytics,
                and customer support. These partners may have access to your
                personal information to perform their functions but are
                obligated to protect it.
              </p>
              <p>
                <strong>Legal Compliance: </strong> We may disclose your
                information if required to do so by law, in response to a
                subpoena, or to protect our rights or the rights of others.
              </p>
              <p>
                <strong>Business Transfers: </strong> If we are involved in a
                merger, acquisition, or asset sale, your information may be
                transferred as part of that transaction.
              </p>
              <p>
                <strong>Keeping Your Information Secure</strong>
              </p>
              <p>
                We implement a variety of security measures to protect your
                personal information:
              </p>
              <p>
                <strong>Encryption: </strong> Sensitive data transmitted over
                the internet is encrypted using Secure Socket Layer (SSL)
                technology.
              </p>
              <p>
                <strong>Access Controls: </strong> Access to personal
                information is restricted to authorized personnel only, who are
                bound by confidentiality obligations.
              </p>

              <p>
                <strong>Regular Audits: </strong> We perform regular security
                assessments to identify and address vulnerabilities.
              </p>
              <p>
                <strong>Exporting & Deleting Your Information</strong>
              </p>
              <p>You have the right to:</p>
              <p>
                <strong>Export Your Data: </strong> Request a copy of your
                personal data that we hold.
              </p>
              <p>
                <strong>Delete Your Data: </strong> Request deletion of your
                personal information under certain conditions, such as when it
                is no longer necessary for the purposes for which it was
                collected.
              </p>
              <p>
                To make these requests, please contact us at{" "}
                <a href="mailto:info@techgv.co.uk">info@techgv.co.uk</a>.
              </p>
              <p>
                <strong>Retaining Your Information</strong>
              </p>
              <p>
                We will retain your personal information only as long as
                necessary to fulfill the purposes outlined in this policy,
                comply with our legal obligations, resolve disputes, and enforce
                our agreements.
              </p>
              <p>
                <strong>Compliance & Cooperation with Regulators</strong>
              </p>
              <p>
                We strive to comply with all applicable privacy laws. We
                cooperate with regulatory authorities in all matters related to
                personal data protection and privacy.
              </p>
              <p>
                <strong>About This Policy</strong>
              </p>
              <p>
                This Privacy Policy may be updated from time to time. We will
                notify you of any significant changes by revising the "Effective
                Date" at the beginning of this document. It is advisable to
                review this policy regularly to stay informed about our privacy
                practices.
              </p>
              <p>
                <strong>Related Privacy Practices</strong>
              </p>
              <p>
                For more information on Google’s general privacy practices,
                please refer to Google’s Privacy Policy at{" "}
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://policies.google.com/privacy
                </a>
                .
              </p>

              <p>
                <strong>Contact Us</strong>
              </p>
              <p>
                If you have any questions, concerns, or requests regarding this
                Privacy Policy, please contact us:
              </p>
              <p>
                <strong>
                  Email:{" "}
                  <a href="mailto:info@techgv.co.uk">info@techgv.co.uk</a>
                </strong>
              </p>
              <p>
                <strong>Address: </strong> [Sadler Bridge Studios Bold Lane
                Derby DE1 3NT]
              </p>
              <p>
                <strong>Website: </strong>
                <a href="https://techgv.co.uk/">techgv.co.uk</a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
