import React from "react";

const InputWithTitle = ({
  placeholder,
  onChange,
  inputTitle,
  height,
  width,
  border,
  margin,
  padding,
  color,
  textMargin,
  textPadding,
  fontFamily,
  fontSize,
  textAlign,
  className,
  textWidth,
  errormesg,
  mesgTextWidth,
  mesgAlign,
  isErrorMesgShow,
  value,
  errorMargin,
  type,
  borderRadius,
  errorPadding,
  disabled,
  getRef
}) => {
  return (
    <div >
      <div className={className}>
        <p
        className="text_field"
          style={{
            color: color,
            margin: textMargin,
            padding: textPadding,
            fontFamily: fontFamily,
            fontSize,
            textAlign: textAlign,
            width: textWidth,
          }}
        >
          {inputTitle}
        </p>
        <input
        ref={getRef}
        disabled={disabled}
          style={{
            height: height,
            width: width,
            border: border,
            margin: margin,
            padding: padding,
            outline: "none",
            borderRadius:borderRadius || '6px'
          }}
          value={value}
          type={type}
          placeholder={placeholder}
          onChange={(val) => onChange(val)}
        />
      </div>
      {isErrorMesgShow && (
        <p
        // className="mx-5"
          style={{
            
            textAlign: "start",
            width: mesgTextWidth,
            margin:errorMargin,
            fontSize: 10,
            color: "red",
            padding:errorPadding
          }}
        >
          {errormesg}
        </p>
      )}
    </div>
  );
};

export default InputWithTitle;
