import React from "react";

const InputWithDropdown = ({
  onSelect,
  label,
  inputValues,
  height,
  width,
  className,
  padding,
  margin,
  textPadding,
  textMargin,
  color,
  fontSize,
  fontFamily,
  border,
  textWidth,
  borderRadius,
  disabled,
  value
}) => {
  return (
    <div className={className}>
      <label
      className="text_field"
        style={{
          margin: textMargin,
          padding: textPadding,
          color: color,
          fontSize: fontSize,
          fontFamily: fontFamily,
          width: textWidth,
        }}
      >
        {label}
      </label>
      <select
      disabled={disabled}
        style={{
          width: width,
          height: height,
          padding: padding,
          margin: margin,
          border: border,
          borderRadius:borderRadius || '7px',
        }}
        onChange={(val) => onSelect(val)}
      >
        {inputValues?.map((item,index) => (
          <option value={item} key={index}>{item}</option>
        ))}
      </select>
    </div>
  );
};

export default InputWithDropdown;
