import React from "react";
import Lottie from "react-lottie";
import animationData from "assets/lottiefiles/Animation - 1718004116241.json";
import { Helmet } from "react-helmet";
const PageNotFound = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div>
       <Helmet>
        <title>Page Not Found - TecHGV</title>
      </Helmet>
      <div className="container">
        <div className="col-md-12">
          <Lottie
            options={defaultOptions}
            style={{ height: "80%", width: "80%" }}
          />
        </div>
      </div>
    </div>
  );
};
export default PageNotFound;
