import React from "react";

const VehicleNumbersDropdown = ({ onChange }) => {
  return (
    <div className="form-group d-flex flex-column align-items-center">
      <label className="text_field text-start w-100">Select Number of Vehicles:</label>
      <select
        className="form-control"
        onChange={onChange}
        style={{ width: "100%", border: "1px solid rgb(191, 191, 191)",padding:'10px 10px' }}
      >
        <option>Vehicles</option>
        {Array.from({ length: 10 }, (_, index) => index + 1).map(
          (item, index) =>
            item > 0 && (
              <option value={item} key={index}>
                {item} Vehicles
              </option>
            ) 
        )}
      </select>
    </div>
  );
};
export default VehicleNumbersDropdown;
