import DownloadButton1 from "assets/img/Download-Button-1-1.png";
import DriverChecksimg from "assets/img/Driver-Checks-mobile-1.png";
import PhoneImg from "assets/img/main-img-1.png";
import TorqueRegisterimg from "assets/img/Torque-Register-mobile-1.png";
import TripTrackinginG from "assets/img/front-img.png";
import TripTrackinginGMobile from "assets/img/trip-tracking-mobile.png";
import VehicleManagementinG from "assets/img/Group-37143-1-2-1.png";
import DriverDefectReportImG from "assets/img/DriverDefectReportImG.png";
import FuelAdblueUsageimg from "assets/img/Fuel-Adblu-Usage-mobile-1.png";
import DriverChecksimgG from "assets/img/Group-37142-1.png";
import VehicleTyreImG from "assets/img/Group-37144-1.png";
import MOTChecksImG from "assets/img/MOT-Checks-mobile-1.png";
import TrainingDocumentsInG from "assets/img/Training-Documents-mobile-1.png";
import TyreWheelManagementImG from "assets/img/Wheel-Management-mobile-1.png";
import "assets/js/main.js";
import "bootstrap/dist/css/bootstrap.min.css";
import SimpleButton from "components/Buttons/SimpleButton";
import InputWithDropdown from "components/Inputs/InputWithDropdown";
import InputWithTitle from "components/Inputs/InputWithTitle";
import ModalALert from "components/Modals/Modal";
import NumberCounter from "components/NumberCounter/NumberCounter.jsx";
import PricingPlan from "components/PricingPlan.js/PricingPlan";
import { IndustryDropdownValue, request_form_empty_field } from "data/FormData";
import "font-awesome/css/font-awesome.min.css";
import "pages/Home/Index.css";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSendData } from "utils/customHooks";
import { validateEmail } from "utils/method";
import { capitalizeEveryWord } from "utils/method";
import MobileImg from "assets/img/Phone_Long_Scroll_Mockup_v01-Recovered-1.png";
import DownloadButton from "data/DownloadButton.jsx";
import GooglePlayDownloadButton from "data/GooglePlayDownloadButton.jsx";
import useMediaQuery from "utils/CustonHooks/UseDeviceResponsiveHook";
import { Helmet } from "react-helmet";

const Home = () => {
  const [infoForm, setInfoForm] = useState({
    first_name: "",
    phone_number: "",
    company_email: "",
    company_name: "",
    number_vehicles: "",
    industry: "",
  });
  const [formHandler, setFormHandler] = useSendData();
  const matches = useMediaQuery("(min-width: 768px)");

  const [isError, setIsError] = useState({
    enabled: false,
    message: "",
  });
  const location = useLocation();
  const ScrollRef = useRef(null);
  //////////////////////////////////////////////////////
  const onChangeInfo = (value, item) => {
    setInfoForm({
      ...infoForm,
      [item]: value,
    });
  };

  useEffect(() => {
    if (location?.state !== null) {
      ScrollRef?.current?.scrollIntoView(true);
    }
  }, [location]);

  useEffect(() => {
    setInfoForm({ ...infoForm, ...request_form_empty_field });
  }, [formHandler?.status]);

  ////////////////////////////////////////////////////////
  const onSelectInfo = (value) => {
    setInfoForm({ ...infoForm, industry: value });
  };

  const onSubmitForm = (event) => {
    event.preventDefault();
    if (
      infoForm.first_name == "" ||
      infoForm.phone_number == "" ||
      infoForm.company_email == "" ||
      infoForm.company_name == "" ||
      infoForm.number_vehicles == "" ||
      !validateEmail(infoForm?.company_email)
    ) {
      setIsError({
        ...isError,
        enabled: true,
        message: "Please fill this empty field of",
      });
    } else {
      setIsError({
        ...isError,
        enabled: false,
      });
      const sendData = {
        ...infoForm,
        status: 2,
        form_name: "Get a Free Quote",
        date: new Date(),
      };
      const formData = new FormData();
      Object?.keys(sendData).forEach((item) => {
        formData.append(
          item == "status" || item == "form_name"
            ? item?.toLowerCase()
            : item?.toLocaleUpperCase(),
          sendData[item]
        );
      });
      setFormHandler((valuestoFormHandler) => ({
        ...valuestoFormHandler,
        email_data: sendData,
        form_data: formData,
        isSpinner: true,
      }));
    }
  };
  return (
    <div>
      <Helmet>
        <title>TecHGV</title>
      </Helmet>
      <section className="hero-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 hero-text-image">
              <div className="row">
                <div className="col-lg-5 text-lg-start">
                  <h1 className="fade-right1">
                    Your Electronic Transport Manager
                  </h1>
                  <p>
                    TecHGV is a multi-language application designed to ensure
                    your fleet stays compliant.
                  </p>

                  <div className="row justify-content-md-normal">
                    <div
                      className="icon-circle"
                      style={{ display: "flex", padding: "0px" }}
                    >
                      <div className="col-md-5">
                        {/* <Link to="/">
                          <img
                            className="fade-right"
                            width={120}
                            src={DownloadButton}
                          />
                        </Link> */}
                        <DownloadButton></DownloadButton>
                      </div>
                      <div className="col-md-2"></div>
                      <div className="col-md-5">
                        <GooglePlayDownloadButton></GooglePlayDownloadButton>
                        {/* <Link tp="https://play.google.com/store/apps/details?id=com.techgv.app&hl=en&gl=US">
                          <img
                            className="fade-right"
                            width={120}
                            src={DownloadButton1}
                          />
                        </Link> */}
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-md-normal">
                    <div className="col-md-12" style={{ padding: "0px" }}>
                      <p className="fade-right">
                        <Link
                          to="https://techgv.online/login"
                          className="btn btn-outline-white btn-Get-started"
                        >
                          LOGIN
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="row justify-content-md-normal">
                    <div
                      className="icon-circle"
                      style={{ display: "flex", padding: "0px" }}
                    >
                      <div className="col-md-5">
                        <p className="fade-right">
                          <Link
                            to="/"
                            className="btn btn-outline-white btn-No-Long"
                          >
                            No Long Contracts
                          </Link>
                        </p>
                      </div>
                      <div className="col-md-2"></div>
                      <div className="col-md-5">
                        <p className="fade-right">
                          <Link
                            to="/"
                            className="btn btn-outline-white btn-No-Long"
                          >
                            Pay as You Go
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-1"></div>
                <div className="col-lg-6 iphone-wrap">
                  <img className="phone-2" src={PhoneImg} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="hero-section-mobile">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 hero-text-image">
              <div className="row">
                <div className="col-lg-6 iphone-wrap">
                  <img className="phone-2" src={PhoneImg} />
                </div>
                <div className="col-lg-1"></div>
                <div className="col-lg-5 text-lg-start">
                  <h1 className="fade-right1">
                    Your Electronic Transport Manager
                  </h1>
                  <p>
                    TecHGV is a multi-language application designed to ensure
                    your fleet stays compliant.
                  </p>

                  <div className="row">
                    <div className="icon-circle" style={{ display: "flex" }}>
                      <div className="col-lg-5">
                        {/* <Link to="/">
                          <img
                            className="fade-right"
                            width={120}
                            src={DownloadButton}
                          />
                        </Link> */}
                      </div>
                      <div className="col-lg-1" style={{ width: "31px" }}></div>
                      <div className="col-lg-5">
                        {/* <Link tp="https://play.google.com/store/apps/details?id=com.techgv.app&hl=en&gl=US">
                          <img
                            className="fade-right"
                            width={120}
                            src={DownloadButton1}
                          />
                        </Link> */}
                      </div>
                    </div>
                  </div>

                  <div className="row" style={{ textAlign: "center" }}>
                    <div
                      className="col-md-4"
                      style={{ width: "48%", paddingRight: "0px" }}
                    >
                      <DownloadButton></DownloadButton>
                    </div>
                    <div
                      className="col-md-4"
                      style={{ width: "4%", padding: "0px" }}
                    ></div>
                    <div
                      className="col-md-4"
                      style={{ width: "48%", paddingLeft: "0px" }}
                    >
                      <GooglePlayDownloadButton></GooglePlayDownloadButton>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <p className="fade-right123" style={{ width: "auto" }}>
                        <Link
                          to="https://techgv.online/login"
                          className="btn btn-outline-white btn-Get-started"
                        >
                          LOGIN
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="row" style={{ textAlign: "center" }}>
                    <div
                      className="col-md-4"
                      style={{ width: "48%", paddingRight: "0px" }}
                    >
                      <p className="fade-right123">
                        <Link
                          to="/"
                          className="btn btn-outline-white btn-No-Long"
                        >
                          No Long Contracts
                        </Link>
                      </p>
                    </div>
                    <div
                      className="col-md-4"
                      style={{ width: "4%", padding: "0px" }}
                    ></div>
                    <div
                      className="col-md-4"
                      style={{ width: "48%", paddingLeft: "0px" }}
                    >
                      <p className="fade-right123">
                        <Link
                          to="/"
                          className="btn btn-outline-white btn-No-Long"
                        >
                          Pay as You Go
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Electronic">
        <div className="container">
          <div className="row align-center Electronic-row">
            <div className="col-md-12  text-center">
              <h2 className="fade-right">
                Trusted By Customers <br /> Across The
                <span style={{ color: "#7ebcb1" }}> Industries </span>
              </h2>
            </div>
          </div>
          <div className="row align-center Electronic-row">
            <div className="col-md-4 text-center">
              <div className=" text-center1">
                <h2 style={{ color: "#7ebcb1", fontSize: "72px" }}>
                  <NumberCounter start={0} end={150} duration={3000} />
                </h2>
                <h3>Customers</h3>
              </div>
            </div>
            <div className="col-md-4  text-center">
              <div className=" text-center1">
                <h2 style={{ color: "#7ebcb1", fontSize: "72px" }}>
                  <NumberCounter start={0} end={325} duration={3000} />
                </h2>
                <h3>Transport Managers</h3>
              </div>
            </div>
            <div className="col-md-4  text-center">
              <div className=" text-center1">
                <h2 style={{ color: "#7ebcb1", fontSize: "72px" }}>
                  <NumberCounter start={0} end={772} duration={3000} />
                </h2>
                <h3>Drivers</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Driver-Checks-sec">
        <div className="container">
          <div className="row align-center Electronic-row">
            <div className="col-md-4 text-center">
              <div className="feature-1">
                <img className="DriverChecksimg" src={DriverChecksimg} />
                <h3 className="mb-3">Driver Checks</h3>
                <p>
                  With our advanced technology and user-friendly platform, you
                  can efficiently manage driver documentation, monitor
                  compliance, and maintain a reliable record of driver checks.
                </p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="feature-1">
                <img className="DriverChecksimg" src={TorqueRegisterimg} />
                <h3 className="mb-3">Torque Register</h3>
                <p>
                  With our intuitive platform, you can easily track, monitor,
                  and maintain accurate records of torque specifications. Trust
                  TecHGV to streamline your torque register process and ensure
                  optimal performance.
                </p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="feature-1 ">
                <img className="DriverChecksimg" src={FuelAdblueUsageimg} />
                <h3 className="mb-3">Fuel & Adblue Usage</h3>
                <p>
                  A comprehensive solution for managing fuel & Adblue usage,
                  helping you optimise efficiency. With our user-friendly
                  platform, you can easily monitor fuel and Adblue levels, track
                  usage patterns.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="OneApp-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 hero-text-image">
              <div className="row">
                <div className="col-lg-6 text-lg-start">
                  <h2 className="fade-right">
                    One App, Infinite <br />
                    <span style={{ color: "#7ebcb1" }}>Possibilities</span>
                  </h2>
                  {!matches && (
                    <div className="text-center">
                      <img
                        className="phone-2"
                        src={DriverChecksimgG}
                        style={{
                          textAlign: "center",
                          padding: "30px 0px 0px 0px",
                        }}
                      />
                    </div>
                  )}
                  <p>
                    TecHGV offers a world of infinite possibilities when it
                    comes to fleet management and vehicle tracking. With our
                    innovative solutions and advanced technology, we empower
                    businesses to unlock new levels of efficiency, productivity,
                    and legal compliance.
                  </p>
                  <br />
                  <p>
                    One of the key strengths of TecHGV lies in its ability to
                    provide comprehensive fleet management software that caters
                    to the unique needs of each client. Whether it’s a small
                    business with a handful of vehicles or a large enterprise
                    with an extensive fleet, TecHGV has the tools and expertise
                    to optimise fleet operations.
                  </p>
                </div>
                <div className="col-lg-6 iphone-wrap text-center">
                  {matches && (
                    <img className="phone-2" src={DriverChecksimgG} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="TripTrackinginG">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <img className="TripTrackinginG1" src={TripTrackinginG} />
            </div>
          </div>
        </div>
      </section>
      <section className="TripTrackinginG-Mobile">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center">
              <img className="TripTrackinginG1" src={TripTrackinginGMobile} />

            </div>
            <div className="col-md-6 text-center">
            <div style={{ textAlign: "center" }}>
                <h1>Trip Tracking</h1>
                <p>
                  At TecHGV, we understand the importance of real-time
                  visibility and control over your transportation operations.
                  For that, we've developed an advanced Trip Tracking feature to
                  empower our users with unparalleled insights into your
                  journeys.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="OneApp-section" style={{ paddingTop: "0px", paddingBottom:"30px" }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 hero-text-image">
              <div className="row">
                <div className="col-lg-6 iphone-wrap text-center">
                  {matches && (
                    <img className="phone-2" src={VehicleManagementinG} />
                  )}
                </div>
                <div className="col-lg-6 text-lg-start DriverTrailerCol">
                  <h2 className="fade-right" style={{ padding: "0px" }}>
                    Driver, Trailer <br /> and Vehicle <br />
                    <span style={{ color: "#7ebcb1" }}>Management</span>
                  </h2>
                  {!matches && (
                    <div className="text-center">
                      <img
                        className="phone-2"
                        src={VehicleManagementinG}
                        style={{
                          textAlign: "center",
                          padding: "30px 0px 0px 0px",
                        }}
                      />
                    </div>
                  )}
                  <p>
                    TecHGV’s solutions seamlessly integrate trailer, vehicle,
                    and driver data into a centralised platform, providing
                    businesses with a holistic view of their entire fleet
                    operations.
                  </p>
                  <br />
                  <p>
                    Fleet managers can access real-time analytics and generate
                    customised reports to gain actionable insights. These
                    insights help in making informed decisions, optimizing
                    resources, and improving overall fleet performance. TecHGV’s
                    analytics capabilities enable businesses to identify trends,
                    patterns, and areas for improvement, driving operational
                    efficiencies and cost savings.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="TripTrackinginG2" style={{ backgroundColor:"#edeff5" }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 hero-text-image">
              <img className="TripTrackinginG1" src={DriverDefectReportImG} />
            </div>
          </div>
        </div>
      </section>

      {/* <PricingPlan getRef={(ref) => (ScrollRef.current = ref)} /> */}

      <section className="Driver-Checks-sec">
        <div className="container">
          <div className="row align-center Electronic-row">
            <div className="col-md-4 text-center">
              <div className="feature-1">
                <img className="DriverChecksimg" src={TrainingDocumentsInG} />
                <h3 className="mb-3">Training Documents </h3>
                <p>
                  TecHGV enables you to track and manage employee training
                  certifications, ensuring that your workforce meets the
                  necessary qualifications and certifications. Maintain
                  compliance effortlessly and mitigate risks.
                </p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="feature-1">
                <img className="DriverChecksimg" src={MOTChecksImG} />
                <h3 className="mb-3">MOT Checks</h3>
                <p>
                  With our cutting-edge technology and experienced team of
                  professionals, we provide comprehensive MOT testing services
                  that go above and beyond standard inspections.
                </p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="feature-1 ">
                <img className="DriverChecksimg" src={TyreWheelManagementImG} />
                <h3 className="mb-3">Tyre/Wheel Management</h3>
                <p>
                  Stay informed about the health of your tyres and wheels.
                  TecHGV’s platform allows you to monitor tyre pressure, tread
                  depth, age, and overall tyre condition. Instantly identify any
                  issues or abnormalities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="GeAFreeQuote w-100">
        <div className="container">
          <div className="row align-center Electronic-row">
            <h2 className="quote-text my-5">Get a Free Quote</h2>

            {Object.keys(infoForm).map((item, index) =>
              item != "industry" ? (
                <InputWithTitle
                  disabled={formHandler?.isSpinner}
                  key={index}
                  textAlign={"start"}
                  inputTitle={capitalizeEveryWord(item)}
                  width={"100%"}
                  padding={"10px 10px"}
                  border={"1px solid #BFBFBF"}
                  textPadding={"10px 0px"}
                  textWidth={"100%"}
                  value={infoForm[item]}
                  mesgTextWidth={"100%"}
                  type={item.includes("number") ? "number" : "text"}
                  errormesg={
                    item == "company_email" &&
                    infoForm["company_email"] !== "" &&
                    !validateEmail(infoForm["company_email"])
                      ? "Please Enter Verified Email"
                      : infoForm[item] == "" && isError?.message
                  }
                  isErrorMesgShow={
                    isError?.enabled ||
                    (item === "company_email" &&
                      infoForm["company_email"] !== "" &&
                      !validateEmail(infoForm[item]))
                  }
                  className={
                    "d-flex flex-column w-100 justify-content-center align-items-center"
                  }
                  onChange={(val) => onChangeInfo(val.target.value, item)}
                />
              ) : (
                <InputWithDropdown
                  disabled={formHandler?.isSpinner}
                  key={index}
                  className={
                    "d-flex flex-column w-100 justify-content-center align-items-center"
                  }
                  value={infoForm?.industry}
                  width={"100%"}
                  textWidth={"100%"}
                  fontSize={18}
                  color={"black"}
                  textPadding={"10px 0px"}
                  padding={"10px 0px"}
                  border={"1px solid #BFBFBF"}
                  label={"What industry is your company in?"}
                  inputValues={IndustryDropdownValue}
                  onSelect={(val) => onSelectInfo(val.target.value)}
                />
              )
            )}
            <div className="d-flex justify-content-right w-100">
              <SimpleButton
                disabled={formHandler?.isSpinner}
                background={"#7fbcb1"}
                color={"white"}
                text={"Submit"}
                textMargin={0}
                margin={"30px 0px"}
                width={matches ? "130px" : "100%"}
                border={0}
                isSpinner={formHandler?.isSpinner}
                onSubmit={(event) => {
                  onSubmitForm(event);
                }}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="OneApp-section" >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 hero-text-image">
              <div className="row">
                <div className="col-lg-6 text-lg-start">
                  <h2 className="fade-right">
                    Vehicle, Tyre <br /> and Torque
                    <br />
                    <span style={{ color: "#7ebcb1" }}>Checks</span>
                  </h2>
                  {!matches && (
                    <div className="text-center">
                      <img
                        className="phone-2"
                        src={VehicleTyreImG}
                        style={{
                          textAlign: "center",
                          padding: "30px 0px 30px 0px",
                        }}
                      />
                    </div>
                  )}
                  <p>
                    TecHGV offers advanced solutions for monitoring and managing
                    vehicle and trailer torque, providing businesses with
                    valuable insights into the performance and efficiency of
                    their fleet.
                  </p>
                  <br />
                  <p>
                    Monitoring vehicle and trailer torque helps businesses
                    optimise performance by identifying and resolving issues
                    that may affect engine power, fuel efficiency, and overall
                    vehicle/trailer performance.
                  </p>
                </div>
                <div className="col-lg-6 iphone-wrap text-center">
                  {matches && <img className="phone-2" src={VehicleTyreImG} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="DriverDefects">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8 iphone-wrap text-center">
              <div className="Mobile-img">
                <img className="phone-2" src={MobileImg} />
              </div>
            </div>
            <div
              className="col-lg-4 text-lg-start  "
              style={{ paddingTop: "30px" }}
            >
              <h2 className="fade-right " style={{ padding: "0px" }}>
                Driver Defects
                <br /> Report
                <span style={{ color: "#7ebcb1" }}> Management</span>
              </h2>
              <p className="text-center">
                TecHGV’s platform allows for immediate synchronisation of data,
                ensuring that you have up-to-date information at your
                fingertips. Stay informed and respond quickly to rectify defects
                and ensure vehicle safety.
              </p>
            </div>
          </div>
        </div>
      </section>
      <ModalALert
        isOpenModal={formHandler?.enableModal}
        animationFile={formHandler?.filetype}
        onClose={() => {
          setFormHandler({ ...formHandler, enableModal: false });
        }}
      />
    </div>
  );
};

export default Home;
