import Form from "components/forms/bookAdemoForm";
import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
export default function Blog({ post }) {
  return (
    <div>
      <Helmet>
        <title>Blog - TecHGV</title>
      </Helmet>
    <section className="hero-section222 " id="hero" style={{ paddingBottom:"30px" }}>
      <div className="container">
        <div className="row">
          <div className="col-md-9 hero-text-image">
            {/* <h1 data-aos="fade-right">Blog</h1> */}
            {post.map((item, index) => (
              <div className="BlogSec" key={index}>
                <div className="BlogPost">
                  <Link to={item.route}>
                    <img src={item.imageUrl} alt={item.title} width={"100%"} />
                  </Link>
                  <div className="row Post-meta">
                    {/* <div className="col-md-1"></div> */}
                    <div className="col-md-12">
                      <h2>{item.title}</h2>
                      <p>{item.content}</p>
                    </div>
                    {/* <div className="col-md-2"></div> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="col-md-3">
          <div style={{ borderRadius: "10px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", padding:"10px 10px" }}>
            <Form />
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  );
}

///////////////
