import React from 'react';

const ThinX = () => (
    <svg width="14" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <line x1="4" y1="4" x2="20" y2="20" stroke="black" strokeWidth="2" />
    <line x1="4" y1="20" x2="20" y2="4" stroke="black" strokeWidth="2" />
  </svg>
);

export default ThinX;
