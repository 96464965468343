import GetRequestForm from "components/forms/GetRequestForm";
import Track2Img from "assets/img/track-2.png";
import "components/index.css";
import { Helmet } from "react-helmet";
function RequestAQuote() {
  return (
    <div>
      <Helmet>
        <title>Request A Quote - TecHGV</title>
      </Helmet>
      <section className="OrderDevice" id="hero">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 hero-text-image">
              <h1 data-aos="fade-right">Request A Quote</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-7" style={{ marginBottom: "50px" }}>
              <GetRequestForm
                boxshadow={"rgba(0, 0, 0, 0.35) 0px 5px 15px"}
                className={"d-flex flex-column align-items-center werwer"}
              />
            </div>
            <div className="col-md-5 ">
              <div
                className="box-shadow"
                style={{ borderRadius: "15px", padding: "20px 20px 20px 20px" }}
              >
                <h3 className="wqd" style={{ color: "#7EBCB1" }}>
                  TecHGV – Your Transport Manager
                </h3>
                <p className="my-3">
                  Sadler Bridge, Studios, Bold Lane, Derby, DE1 3NT
                </p>
                <p className="my-3">01332 323 989</p>
                <p
                  className="my-3"
                  style={{ borderBottom: "1px solid #BFBFBF", width: "100%" }}
                ></p>
                <h2
                  className="my-3"
                  style={{ color: "#868686", textAlign: "center" }}
                >
                  Services and Guarantees
                </h2>
                <p className="my-3 w-100">
                  You need a tracking service that stands behind their product.
                  We offer years of experience and the guarantees to back it up.
                </p>
                <div className="d-flex align-items-center">
                  <img src={Track2Img} height={20} width={20} />
                  <p className="mx-3 my-2">No long contracts</p>
                </div>
                <div className="d-flex align-items-center">
                  <img src={Track2Img} height={20} width={20} />
                  <p className="mx-3 my-2"> Pay as you go</p>
                </div>
                <div className="d-flex align-items-center">
                  <img src={Track2Img} height={20} width={20} />
                  <p className="mx-3 my-2" style={{ textAlign: "left" }}>
                    Customer & signatory dashboards
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <img src={Track2Img} height={20} width={20} />
                  <p className="mx-3 my-2" style={{ textAlign: "left" }}>
                    Radical customer support
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <img src={Track2Img} height={20} width={20} />
                  <p className="mx-3 my-2" style={{ textAlign: "left" }}>
                    {" "}
                    Quality UK-based GPS Devices
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <img src={Track2Img} height={20} width={20} />
                  <p className="mx-3 my-2" style={{ textAlign: "left" }}>
                    {" "}
                    Android and iOS Mobile Apps
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default RequestAQuote;
