import React from 'react';
import thsplusBlog from 'assets/img/thsplus-Blog-img-1.png';
import ComericalVehicle from 'assets/img/Comerical-Vehicle-Tracking-System.png';
import ByIngoude from 'assets/img/By-Ingoude-Company-1.png';
import VehicleAlignment from 'assets/img/Vehicle-Alignment.jpeg';

const posts = [
    {
      id: 1,
      
      title: 'Fleet Management Services & Solutions',
      content: 'Fleet management services encompass a range of comprehensive solutions and support systems designed to streamline the management and operation of a fleet of vehicles. These services are often employed by businesses or organizations that rely on a fleet of vehicles for...',
    //   author: 'Author 1',
    //   date: 'April 30, 2024',
      imageUrl:  thsplusBlog,
      route:'/Fleet-Management-Services-Solutions'
      

    },
    {
      id: 2,
      title: 'Commercial Vehicle Tracking System',
      content: 'Commercial Vehicle Tracking System Introduction: In the fast-paced world of commercial transportation, businesses are constantly seeking innovative solutions to optimize their fleet operations. One such technology that has gained significant traction is the commercial...',
    //   author: 'Author 2',
    //   date: 'April 29, 2024',
      imageUrl: ComericalVehicle,
      route:'/Commercial-Vehicle-Tracking-System'

    },
    {
      id: 3,
      title: 'Vehicle Daily Checks Importance',
      content: 'Vehicle daily checks are a fundamental aspect of responsible vehicle ownership, maintenance, and operation. Whether you drive a personal car, manage a commercial fleet, or simply use vehicles as part of your daily routine, conducting regular daily checks is a critical...',
    //   author: 'Author 3',
    //   date: 'April 28, 2024',
      imageUrl:  ByIngoude,
      route:'/Vehicle-Daily-Checks-Importance'
    },
    {
    id: 4,
    title: 'Vehicle Alignment signs that you need maintenance',
    content: 'Proper wheel alignment is a crucial aspect of vehicle maintenance often overlooked by many drivers. It involves adjusting the angles of the wheels to ensure they are parallel to each other and perpendicular to the ground. When your vehicle’s alignment is off, it...',
    // author: 'Author 3',
    // date: 'April 28, 2024',
    imageUrl:  VehicleAlignment,
    route:'/Vehicle-Alignment'
  },
    //////////
  ];
  
  export default posts;
  

// import React from 'react';

// const Post = ({ posts, match }) => {
//   const postId = parseInt(match.params.postId); // Get post ID from URL
//   const post = posts.find(post => post.id === postId); // Find post by ID

//   if (!post) {
//     return <div>Post not found!</div>;
//   }

//   return (
//     <div className="post">
//       <h2>{post.title}</h2>
//       {post.image && <img src={post.image} alt="Post" />}
//       <p>{post.content}</p>
//       <p>Author: {post.author}</p>
//       <p>Date: {post.date}</p>
//     </div>
//   );
// };

// export default Post;
