import React, { useState, useEffect, useRef } from 'react';

const NumberCounter = ({ start, end, duration }) => {
  const [currentNumber, setCurrentNumber] = useState(start);
  const [isInView, setIsInView] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.disconnect(); // Stop observing after first intersection
        }
      },
      { threshold: 0.1 } // Adjust the threshold as needed
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!isInView) return;

    const startTime = Date.now();
    const endTime = startTime + duration;

    const updateNumber = () => {
      const now = Date.now();
      const timePassed = now - startTime;
      const progress = Math.min(1, timePassed / duration);
      const nextNumber = Math.floor(progress * (end - start) + start);

      setCurrentNumber(nextNumber);

      if (now < endTime) {
        requestAnimationFrame(updateNumber);
      }
    };

    requestAnimationFrame(updateNumber);

    return () => {
      // Clean up if necessary
    };
  }, [isInView, start, end, duration]);

  return <span ref={ref}>{currentNumber}</span>;
};

export default NumberCounter;
