import React, { useContext, useEffect, useLayoutEffect, useRef } from "react";
import weblogo from "assets/img/logo.png";
import { Link, NavLink  } from "react-router-dom";

import "assets/js/main";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useLocation } from "react-router-dom";
import "components/header/header.css";
import { NavButtonClosed } from "StateManagement/Context";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
export default function Header() {
  const [expanded, setExpanded] = useState(false);
  const closeNavbar = () => setExpanded(false);

  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/", { state: "Pricing" });
  };



  return (
    <div className="container d-flex justify-content-between align-items-center">
      
      <Navbar expand="lg" expanded={expanded} className="w-100 d-flex justify-content-between">
        <Navbar.Brand>
          <div className="logo">
            <Link to="/">
              <img className="img-fluid"  src={weblogo} />
            </Link>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse id="basic-navbar-nav">
       
          <Nav  onClick={closeNavbar}>
            <NavLink className="nav-link" to="/">
              Home
            </NavLink>
            <NavLink  className="nav-link pointer" to={'/pricing-plan'}  >
              Pricing
            </NavLink>
            <NavLink className="nav-link" to="/order-a-device">
              Order a Device
            </NavLink>
            <NavLink className="nav-link" to="/book-a-demo">
              Book a Demo
            </NavLink>
            <NavLink className="nav-link" to="/get-a-free-quote">
              Get a Free Quote
            </NavLink>
            <NavLink className="nav-link" to="/blog">
              Blog
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}
