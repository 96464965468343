import React from 'react';
import DownloadButton from "assets/img/Download-Button-1.png";
import { Link } from 'react-router-dom';

function DownloadButton123() {
  return (
    <Link to="https://apps.apple.com/gb/app/techgv/id1587288931">
      <img
        className="fade-right"
        width={100}
        src={DownloadButton}
        alt="Download Button"
      />
    </Link>
  );
}

export default DownloadButton123;