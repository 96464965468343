export const NotRequiredDeviceForm = [
  "vehicle_type",
  "form_select",
  "vehicle_number",
  "isCheck",
  "isRequired",
  "isOpenModal",
  "isSpinner",
  "isErrorMessage",
  "isMailError",
];

export const IndustryDropdownValue = [
  "-Select-",
  "Trucking",
  "Oil and Gas",
  "Constrution",
  "Food and Beverages",
  "Field Services",
  "All Others",
];
export const request_form_empty_field = {
  first_name: "",
  phone_number: "",
  company_email: "",
  company_name: "",
  number_vehicles: "",
  industry: "",
};



export const requestFormEmptyFiled={
  company_name: "",
  o_license: "",
  address: "",
  country: "",
  state: "",
  city: "",
  address_line: "",
  postal_code: "",
  email_address: "",
  phone: "",
  isRequired: false,
  vehicle_number: "",
  isErrorMessage: "",
}
export const validateEmail = "/^[^s@]+@[^s@]+.[^s@]+$/";
export const inputTypeCheck = /(number|code|vehicles)/;

export const bookDemoEmptyField={
  name: "",
  phone: "",
  email: "",
  lookingFor: "",
  region: "",
  fleetSize: "",
  remarks: "",
}

export const getFreeQuote={
  name: "",
  company_name: "",
  work_email: "",
  phone_number: "",
  postal_code: "",
  vehicles: "",
  trailer_Equipment: "",
  comments: "",
}