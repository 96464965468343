import React from "react";
import "components/Buttons/Buttons.css";
import { type } from "@testing-library/user-event/dist/type";
import Spinner from "react-bootstrap/Spinner";
const SimpleButton = ({
  background,
  height,
  width,
  margin,
  padding,
  text,
  color,
  fontFamily,
  fontSize,
  textAlign,
  onSubmit,
  textMargin,
  textPadding,
  border,
  type,
  isSpinner,
  disabled
}) => {
  return (
    <button
    disabled={disabled}
      style={{
        background: background,
        height: height,
        width: width,
        margin,
        padding,
        border: border,
      }}
      type={type}
      className="btn btn-primary button-simple"
      onClick={(event) => onSubmit(event)}
    >
      {isSpinner ? (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <p
          style={{
            color: color,
            fontFamily: fontFamily,
            fontSize: fontSize,
            textAlign: textAlign,
            margin: textMargin,
            padding: textPadding,
          }}
        >
          {text}
        </p>
      )}
    </button>
  );
};

export default SimpleButton;
