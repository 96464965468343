import axios from "axios";
import { base_url } from "env/appConstant";

export const Send_Email = async (data) => {
  const response = {
    url: `https://downloader.techvezoto.online/api/send-email`,
    method: "POST",
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.request(response);
};

export const Send_Form_Data = async (data) => {
  const response = {
    url: `https://script.google.com/macros/s/AKfycbxXPIWAhjnmnCMfg8YajbwTO8ly7BBqXxTokFVzY0dur-Dpsl01Y8QKx1zzerzLHFKi/exec`,
    method: "POST",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  console.log(Array.from(data));
  return await axios?.request(response);
};
