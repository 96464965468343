import React from "react";
import VehicleForm from "components/forms/OrderDeviceForm.jsx";
import { Helmet } from "react-helmet";
const OrderDevice = (props) => {
  return (
    <div>
      <Helmet>
        <title>Order a Device - TecHGV</title>
      </Helmet>
      <section className="OrderDevice" id="hero">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-12 hero-text-image"
              style={{ padding: "0px 0px 30px 0px" }}
            >
              <h1
                data-aos="fade-right"
                style={{ padding: "30px 0px 30px 0px" }}
              >
                Order a Device
              </h1>

              <VehicleForm className={"w-50"} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OrderDevice;
