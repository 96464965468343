import { Send_Email, Send_Form_Data } from "Networking/networking";
import SimpleButton from "components/Buttons/SimpleButton";
import InputWithTitle from "components/Inputs/InputWithTitle";
import ModalALert from "components/Modals/Modal";
import { inputTypeCheck } from "data/FormData";
import React, { useEffect, useState } from "react";
import { useSendData } from "utils/customHooks";
import useMediaQuery from 'utils/CustonHooks/UseDeviceResponsiveHook';
import { validateEmail } from "utils/method";
import { getFreeQuote } from "data/FormData";
import { capitalizeEveryWord } from "utils/method";
const GetRequestForm = ({ boxshadow, className, maxWidth }) => {
  const [inputForm, setInputForm] = useState({
    name: "",
    company_name: "",
    work_email: "",
    phone_number: "",
    postal_code: "",
    vehicles: "",
    trailer_Equipment: "",
    comments: "",
  });
  const matches = useMediaQuery("(min-width: 768px)");
  const [isError, setIsError] = useState(false);
 const [formHandler,setFormHandler]=useSendData()
 useEffect(() => {
setInputForm({...inputForm,...getFreeQuote})
}, [formHandler?.status]);
  const onHandleChange = (val, item) => {
    setInputForm({ ...inputForm, [item]: val });
  };

  const handel_Submit = (e) => {
    e.preventDefault();


    if (
      inputForm?.name == "" ||
      inputForm?.company_name == "" ||
      inputForm?.work_email == "" ||
      inputForm?.postal_code == "" ||
      inputForm?.vehicles == "" ||
      inputForm?.trailer_Equipment == "" ||
      inputForm?.comments == "" ||
      !validateEmail(inputForm?.work_email)
    ) {
      setIsError(true);
    } else {
      const send_email_for_request = {
        ...inputForm,
        status: 4,
        date: new Date(),
        form_name: "Request a Quote",
      };

      const formDataForReuestQuote = new FormData();
      Object?.keys(send_email_for_request)?.forEach((item) => {
        formDataForReuestQuote.append(
          item == "status"
            ? "status"
            : item == "form_name"
            ? "form_name"
            : item?.toLocaleUpperCase(),
            send_email_for_request[item]
        );
      });
      setFormHandler((valuestoFormHandler) => ({
        ...valuestoFormHandler,
        email_data: send_email_for_request,
        form_data: formDataForReuestQuote,
        isSpinner: true,
      }));
      


    }
  };
  return (
    <div>
      <div className=" rewfgref" style={{ borderRadius: 15, boxShadow: boxshadow, padding:"20px" }}>
        <div className="d-flex flex-column align-items-center werwer">
          <h2 style={{ color: "#868686", textAlign: "center" }}>
            Why Request A Quote?
          </h2>
          <p style={{ textAlign: "center" }}>
            We have many different service options to fit your every need.
            Request a no-obligation free quote so we can give you the Best
            Service at the Best Price for your business.
          </p>
        </div>
        <form>
          {Object.keys(inputForm).map((item, index) => (
            <div key={index}>
              <InputWithTitle
                disabled={formHandler?.isSpinner}
                key={index}
                color={"#BFBFBF"}
                errormesg={
                  item == "work_email" && !validateEmail(inputForm[item] !== "")
                    ? "Please Enter Verified Email"
                    : "This Field is required"
                }
                border={"1px solid #BFBFBF"}
                
              
                width={maxWidth || "100%"}
                isErrorMesgShow={
                  (inputForm[item] == "" && isError) ||
                  (item == "work_email" &&
                    inputForm[item] !== "" &&
                    !validateEmail(inputForm[item]))
                }
               
                errorMargin={"0px 500px !important"}
                height={"50px"}
                padding={"10px 10px"}
                value={inputForm[item]}
                type={inputTypeCheck.test(item) ? "number" : "text"}
                inputTitle={capitalizeEveryWord(item)}
                placeholder={capitalizeEveryWord(item)}
                className={className}
                errorPadding={"0px 0px"}
                onChange={(val) => {
                  onHandleChange(val.target.value, item);
                }}
              />
              {index == 5 && (
                <h4 style={{ color: "black", textAlign: "center" }}>
                  How many Tracking Devices?
                </h4>
              )}
            </div>
          ))}
          <SimpleButton
            disabled={formHandler?.isSpinner}
            background={"#7fbcb1"}
            color={"white"}
            text={"Submit"}
            textMargin={"auto"}
            margin={"20px 10px"}

            width={matches ? "130px" : '100%'}
            isSpinner={formHandler?.isSpinner}
            border={0}
            onSubmit={(e) => {
              handel_Submit(e);
            }}
          />

          <div className="text-center d-flex justify-content-center align-items-center">
            <p style={{ fontSize: 11, textAlign: "center" }}>
              If you submit your request M-F 8:30-5PM CST, you will receive a
              response within an hour. If after hours, we’ll get to it first
              thing the next day.
            </p>
          </div>
        </form>
      </div>
      <ModalALert
        isOpenModal={formHandler?.enableModal}
        animationFile={formHandler?.filetype}
        onClose={() => {
          setFormHandler({...formHandler,enableModal:false})
        }}
      />
    </div>
  );
};

export default GetRequestForm;
