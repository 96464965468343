import Modal from "react-bootstrap/Modal";
import React from "react";
import Button from "react-bootstrap/Button";
import Lottie from "react-lottie";
import SimpleButton from "components/Buttons/SimpleButton";
function ModalALert({ isOpenModal, modalMessage, onClose ,animationFile}) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationFile,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Modal show={isOpenModal} style={{ position: "fixed", top: "30%" }}>
      <Modal.Dialog>
        <p style={{ color: "#3F5E58", fontSize: 30, padding: "10px" }}>
        {modalMessage}
        </p>
        <Lottie options={defaultOptions} height={150} width={150} />
      </Modal.Dialog>
      <div className="d-flex justify-content-center py-3">
        <SimpleButton
          width={"30%"}
          background={"#3F5E58"}
          border={"1px solid #3F5E58"}
          text={"Close"}
          onSubmit={() => onClose()}
        />
      </div>
    </Modal>
  );
}

export default ModalALert;
