import InputWithTitle from "components/Inputs/InputWithTitle";
import React, { useEffect } from "react";

const VehicleNumbersofInput = ({
  vehicle_number,
  onChange,
  values,
  isCheck,
  onChecked,
}) => {
  useEffect(() => {
    console.log(vehicle_number);
  }, []);

  return (
    <div className="form-group">
      {Array.from({ length: vehicle_number }, (_, index) => index + 1).map(
        (item, index) => (
          <div key={index}>
            <label className="text_field">Vehicle Registration Number:</label>
            <InputWithTitle
              value={values[item]?.value}
              width={'100%'}
              borderRadius={'10px'}
              label={"Vehicle Registration Number"}
              padding={"10px 10px"}
              textWidth={'100%'}
              border={"1px solid #BFBFBF"}
              className={'d-flex flex-column align-items-center'}
              type={"text"}
              placeholder={`Vehicle ${item}`}
              onChange={(val) => onChange(val.target.value, item - 1)}
            />

            <div className="d-flex my-2">
            <input
                type="checkbox"
                checked={isCheck[item]?.isChecked}
                onChange={(val) => onChecked(val?.target?.checked, item - 1)}
              />
              <p style={{ paddingLeft:"20px" }}>Telemetics</p>
             
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default VehicleNumbersofInput;
