import React from "react";
import ThinX from 'assets/svg/ThinX.js'
import RightSymbol from "assets/svg/RightSymbol.js"
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const PricingPlan = ({getRef}) => {
  return (
    <div>
      <Helmet>
        <title>Pricing Plan - TecHGV</title>
      </Helmet>
    <section className="TripTrackinginG3 " id="Pricing" ref={getRef}>
      <div className="container">
        <div className="row align-center Electronic-row">
          <div className="col-md-12 text-center">
            <div className="feature-12">
              <h2 className="mb-3">
                Reduce your Fleet & Assets <br /> Management costs today!
              </h2>
              <p>Simple, scalable pricing. No contract plans available.</p>
            </div>
          </div>
        </div>
        <div className="row align-center Electronic-row">
          <div className="col-md-3 feature-2">
            <div className="feature-1">
              <h5 className="col-mb-3">Basic</h5>
              <p>
                Ideal for individuals who need quick access to basic features.
              </p>
              <h6
                style={{
                  fontSize: "40px",
                  color: "#666",
                  fontFamily: "Poppins",
                }}
              >
                £ 5
                <span
                  style={{
                    fontWeight: "300",
                    color: "#b3b3b3",
                    fontSize: "15px",
                    fontFamily: "Poppins, Helvetica, Arial, Lucida, sans-serif",
                  }}
                >
                  / Per Vehicle - Month
                </span>
              </h6>
              <p className="fade-right">
                <Link to="/book-a-demo" className=" btn btn-outline-white ReduceyourFleet">
                  Get Started Now
                </Link>
              </p>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className=" circle"><RightSymbol/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Driver Checks</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className="circle"></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Fuel & Adblue Usage</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className="circle"><RightSymbol/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Auditor Transport Manager Access</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className=" circle"><RightSymbol/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Torque Register</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className=" circle"><RightSymbol/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Training Document Management</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className=" circle"><RightSymbol/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Admin Web Dashboard</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className=" circle"><RightSymbol/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>T-Drive</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className=" circle-x"><ThinX/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Tachograph Analysis</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                <span className=" circle-x"><ThinX/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Remote tachograph download</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                <span className=" circle-x"><ThinX/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Vehicle tracking</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                <span className=" circle-x"><ThinX/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>KPI Analysis</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                <span className=" circle-x"><ThinX/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Automated DVSA Emails</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                <span className=" circle-x"><ThinX/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Fuel Theft Notification</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                <span className=" circle-x"><ThinX/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Earned Recognition Features</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-1"></div>
          <div className="col-md-3 feature-3">
            <div className="feature-1">
              <h5 className="col-mb-3" style={{ color: "#fff" }}>
                Norn
              </h5>
              <p>
                Ideal for individuals who need advanced features and tools for
                client work.
              </p>
              <h6
                style={{
                  fontSize: "40px",
                  color: "#e8e8e8",
                  fontFamily: "Poppins",
                }}
              >
                £ 20
                <span
                  style={{
                    fontWeight: "300",
                    color: "#b3b3b3",
                    fontSize: "15px",
                    fontFamily: "Poppins, Helvetica, Arial, Lucida, sans-serif",
                  }}
                >
                  / Per Vehicle - Month
                </span>
              </h6>
              <p className="fade-right">
                <Link to="/book-a-demo" className=" btn btn-outline-white ReduceyourFleet">
                  Get Started Now
                </Link>
              </p>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className=" circle"><RightSymbol/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Driver Checks</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className=" circle"><RightSymbol/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Fuel & Adblue Usage</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className=" circle"><RightSymbol/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Auditor Transport Manager Access</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className=" circle"><RightSymbol/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Torque Register</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className=" circle"><RightSymbol/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Training Document Management</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className=" circle"><RightSymbol/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Admin Web Dashboard</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className=" circle"><RightSymbol/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>T-Drive</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className=" circle"><RightSymbol/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Tachograph Analysis</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className=" circle"><RightSymbol/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Remote tachograph download</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className=" circle"><RightSymbol/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Vehicle tracking</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className=" circle-x"><ThinX/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>KPI Analysis</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className="circle-x"><ThinX/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Automated DVSA Emails</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className=" circle-x"><ThinX/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Fuel Theft Notification</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className=" circle-x"><ThinX/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Earned Recognition Features</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-1"></div>
          <div className="col-md-3  feature-2">
            <div className="feature-1 ">
              <h5 className="col-mb-3">Earned Recognition</h5>
              <p>
                Ideal for individuals who need quick access to Earned
                Recognition features.
              </p>
              <h6
                style={{
                  fontSize: "40px",
                  color: "#666",
                  fontFamily: "Poppins",
                }}
              >
                £ 35
                <span
                  style={{
                    fontWeight: "300",
                    color: "#b3b3b3",
                    fontSize: "15px",
                    fontFamily: "Poppins, Helvetica, Arial, Lucida, sans-serif",
                  }}
                >
                  / Per Vehicle - Month
                </span>
              </h6>
              <p className="fade-right">
                <Link to="/book-a-demo" className=" btn btn-outline-white ReduceyourFleet">
                  Get Started Now
                </Link>
              </p>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className=" circle"><RightSymbol/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Driver Checks</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className=" circle"><RightSymbol/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Fuel & Adblue Usage</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className=" circle"><RightSymbol/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Auditor Transport Manager Access</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className=" circle"><RightSymbol/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Torque Register</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className=" circle"><RightSymbol/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Training Document Management</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className=" circle"><RightSymbol/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Admin Web Dashboard</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className=" circle"><RightSymbol/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>T-Drive</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className=" circle"><RightSymbol/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Tachograph Analysis</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                  <span className=" circle"><RightSymbol/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Remote tachograph download</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                <span className=" circle"><RightSymbol/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Vehicle tracking</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                <span className=" circle"><RightSymbol/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>KPI Analysis</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                <span className=" circle"><RightSymbol/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Automated DVSA Emails</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                <span className=" circle"><RightSymbol/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Fuel Theft Notification</p>
                </div>
              </div>
              <div
                className="icon-circle"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-sm-2">
                <span className=" circle"><RightSymbol/></span>
                </div>
                <div className="col-mb-4" style={{ paddingTop: "3px" }}>
                  <p>Earned Recognition Features</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  );
};

export default PricingPlan;
